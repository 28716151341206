#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  pointer-events: none;
}
#header .header_inner {
  display: flex;
  padding: 68px 0 0;
  justify-content: flex-end;
  z-index: 999;
  position: relative;
}

#header .mintBtn {
  width: 144px;
  height: 35px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 17.5px;
  position: relative;
  margin-right: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  pointer-events: auto;
}
#header .mintBtn span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
}

#header .mintBtn:before,
#header .mintBtn:after {
  content: "Live Minting ";
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 35px;
  text-align: center;
  font-size: 21px;
  color: #d6d6d6;
  z-index: 1;
}

#header .mintBtn:before {
  left: 0;
  animation: marquee 5s linear infinite;
}

#header .mintBtn:after {
  left: 100%;
  animation: marquee 5s linear infinite;
  animation-delay: 2.5s;
}

@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%;
  }
}

#header .icon {
  position: relative;
  width: 39px;
  height: 35px;
  display: inline-block;
  cursor: pointer;
  pointer-events: auto;
}

.bar {
  width: 100%;
  height: 5px;
  background-color: #fff;
  margin: 4px 0;
  position: absolute;
  border-radius: 2.5px;
}

.icon .bar.one {
  top: 0;
  left: 0;
}
.icon .bar.two {
  top: 10px;
  left: 0;
}
.icon .bar.three {
  top: 20px;
  left: 0;
}

.icon .bar.one.active {
  animation: one 0.5s forwards;
}
.icon .bar.two.active {
  animation: two 0.5s forwards;
}
.icon .bar.three.active {
  animation: three 0.5s forwards;
}

.icon .bar.one.notActive {
  animation: one-reverse 0.5s forwards;
}
.icon .bar.two.notActive {
  animation: two-reverse 0.5s forwards;
}
.icon .bar.three.notActive {
  animation: three-reverse 0.5s forwards;
}

#header nav {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  right: -100%;
  background: rgba(16, 16, 16, 0.88);
  text-align: center;
  transition: all 0.5s ease-in-out;
  pointer-events: auto;
}
#header nav.active {
  right: 0;
}
#header nav ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#header nav ul a {
  color: #fff;
  font-size: 100px;
  font-weight: 100;
  position: relative;
  display: inline-block;
  line-height: 1.4;
}
#header nav ul a::before {
  content: "";
  width: 0%;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 20px;
}
#header nav ul a:hover::before {
  width: 100%;
  transition: width 0.3s ease;
}
#header nav ul li.activeLink a {
  font-weight: normal;
}
@keyframes one {
  0% {
    top: 0;
  }
  30% {
    top: -5px;
  }
  60% {
    top: 10px;
  }
  80% {
    transform: rotate(0);
  }
  100% {
    top: 10px;
    transform: rotate(45deg);
  }
}

@keyframes one-reverse {
  0% {
    top: 10px;
    transform: rotate(45deg);
  }
  30% {
    transform: rotate(0);
  }
  60% {
    top: 10px;
  }
  80% {
    top: -5px;
  }
  100% {
    top: 0;
  }
}

@keyframes two {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes two-reverse {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes three {
  0% {
    top: 20px;
  }
  30% {
    top: 25px;
  }
  60% {
    top: 10px;
  }
  80% {
    transform: rotate(0);
  }
  100% {
    top: 10px;
    transform: rotate(-45deg);
  }
}

@keyframes three-reverse {
  0% {
    top: 10px;
    transform: rotate(-45deg);
  }
  30% {
    transform: rotate(0);
  }
  60% {
    top: 10px;
  }
  80% {
    top: 25px;
  }
  100% {
    top: 20px;
  }
}
