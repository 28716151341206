@font-face {
  font-family: "Impact Regular";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/impact.woff");
}

@font-face {
  font-family: "Futura";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/Futura-Medium.eot");
  src: url("./fonts/Futura-Medium.woff") format("woff"),
    url("./fonts/Futura-Medium.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree&display=swap");

.App {
  text-align: center;
}
html {
  overflow-y: scroll;
}
body {
  margin: 0;
  padding: 0;

  /* font-family: "Noto Sans KR", sans-serif; */
  font-family: "Noto Sans", sans-serif;
}
html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img,
p {
  margin: 0;
  padding: 0;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}
label,
input,
button,
select,
img {
  vertical-align: middle;
  font-size: 1em;
}
input,
button {
  margin: 0;
  padding: 0;
  font-family: "Malgun Gothic", dotum, sans-serif;
  font-size: 1em;
}
input[type="submit"] {
  cursor: pointer;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cf::after {
  content: "";
  display: table;
  clear: both;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
