.pc {
  display: block;
}
.tablet {
  display: none;
}
.tablet2 {
  display: none;
}
.mobile {
  display: none;
}
.mobileOnly {
  display: none;
}
.m_imgBox {
  display: none !important;
}
@media (max-width: 1280px) {
  .pc {
    display: none;
  }
  .tablet2 {
    display: block;
  }
  .tablet {
    display: none;
  }
  .mobile {
    display: none;
  }
  #header .header_inner {
    padding: 3vw 0 0 !important;
  }
  #header nav li {
    margin: 2vw 0;
  }
  #header nav ul a {
    font-size: 6.02vw;
  }
  #header nav ul a::before {
    bottom: 0;
  }
  .height100 {
    height: auto;
  }
  .height100 .sec_inner {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);
  }
  .sec_inner {
    width: 84%;
  }

  .around_title h2 {
    font-size: 2.03vw;
    width: 33%;
  }
  .around_title p {
    font-size: 2.03vw;
  }
  #main h1 {
    font-size: 4.45vw;
  }
  #section02 {
    padding: 13.02vw 0 16.09vw;
  }
  #section02 p {
    font-size: 1.8vw;
  }
  #section03 {
    display: none;
  }
  #section03_m {
    display: block;
    color: #fff;
  }
  #section03_m .sec_inner {
    position: relative;
  }
  #section03_m .forceBg {
    position: absolute;
    top: -3vw;
    left: 0;
    width: 100%;
  }

  #section03_m .peopleImg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  #section03_m .peopleImg img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #section03_m .text_wrap {
    width: 100%;
  }
  #section03_m svg {
    width: 100%;
  }

  #section03_m .svg-container {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  #section03_m .imgWrap {
    position: relative;
    padding-top: 4vw;
  }

  #section03_m .peopleImg .people {
    z-index: 1;
  }
  #section03_m .peopleImg .shadow {
    top: 45%;
    left: 45%;
  }
  #section03_m h3 {
    margin: 4.37vw 0 2.5vw;
  }
  #section03_m .detailText {
    display: flex;
    position: relative;
    justify-content: space-around;
  }
  #section03_m .detailText::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 48%;
    transform: translateX(-50%);
    background: rgba(112, 112, 112, 0.19);
  }
  #section03_m .detailText > div {
    width: 50%;
  }
  #section03_m .detailText > div:first-child {
    padding-right: 2%;
  }
  #section03_m .detailText > div:last-child {
    padding-left: 2%;
  }

  #section03_m .detailText h4 {
    font-size: 1.41vw;
    font-weight: 900;
    color: #fff;
  }
  #section03_m .detailText p {
    font-size: 1.72vw;
    color: #fff;
    font-weight: 100;
    line-height: 1.72;
    letter-spacing: -0.06vw;
    word-break: keep-all;
  }
  #section03_m .atman .peopleImg {
    width: 44.38vw;
  }

  #section03_m .looper .peopleImg {
    width: 40vw;
  }

  #section03_m .hide .peopleImg {
    width: 46vw;
  }

  #section03_m h3 img {
    width: auto;
    height: 2.27vw;
  }

  #section03_m .atman text {
    fill: transparent;
    stroke: #d15eb1;
  }
  #section03_m .looper text {
    fill: transparent;
    stroke: #78e58f;
  }

  #section03_m .hide text {
    fill: transparent;
    stroke: #5050eb;
  }

  #section03_m .atman .t07,
  #section03_m .atman .t02 {
    animation: atmanColor01 3s steps(1, start) infinite;
  }
  #section03_m .atman .t04,
  #section03_m .atman .t08,
  #section03_m .atman .t03 {
    animation: atmanColor02 3s steps(1, start) infinite;
  }
  #section03_m .atman .t01,
  #section03_m .atman .t05,
  #section03_m .atman .t09 {
    animation: atmanColor03 3s steps(1, start) infinite;
  }
  #section03_m .looper .t07,
  #section03_m .looper .t02 {
    animation: looperColor01 3s steps(1, start) infinite;
  }
  #section03_m .looper .t04,
  #section03_m .looper .t08,
  #section03_m .looper .t03 {
    animation: looperColor02 3s steps(1, start) infinite;
  }
  #section03_m .looper .t01,
  #section03_m .looper .t05,
  #section03_m .looper .t09 {
    animation: looperColor03 3s steps(1, start) infinite;
  }
  #section03_m .hide .t07,
  #section03_m .hide .t02 {
    animation: hideColor01 3s steps(1, start) infinite;
  }
  #section03_m .hide .t04,
  #section03_m .hide .t08,
  #section03_m .hide .t03 {
    animation: hideColor02 3s steps(1, start) infinite;
  }
  #section03_m .hide .t01,
  #section03_m .hide .t05,
  #section03_m .loohideper .t09 {
    animation: hideColor03 3s steps(1, start) infinite;
  }

  #section04 {
    margin-top: 19.06vw;
    height: auto;
  }
  #section04 .sec_inner {
    position: relative;
    height: auto;
    top: auto;
  }
  #section04 .text_wrap {
    padding-top: 4.54vw;
  }
  #section04 .text_wrap .num {
    font-size: 18.13vw;
  }
  #section04 h3 {
    font-size: 2.03vw;
    line-height: 1.58;
  }
  #section04 p {
    margin-top: 3.36vw;
    padding-top: 3.36vw;
    font-size: 2.03vw;
    line-height: 1.58;
  }
  #section04 .img_wrap {
    position: relative;
    top: auto;
    right: auto;
    justify-content: center;
    gap: 3.9%;
    margin-top: 27.66vw;
  }
  #section04 .img_wrap .imgBox {
    width: 26.4%;
  }
  #section04 .img_wrap .imgOne {
    transform: translateY(-9.67vw);
  }
  #section04 .img_wrap .imgTwo {
    transform: translate(0);
  }
  #section04 .img_wrap .imgThree {
    transform: translateY(-20.57vw);
  }

  #section05 {
    margin-top: 13.67vw;
  }
  #section05 .sec5_title h2 {
    font-size: 4.61vw;
  }
  #section05 .sec5_title p {
    font-size: 2.03vw;
    margin: 3.44vw 0 2.34vw;
  }
  #section05 .timeWrap .time {
    font-size: 12.34vw;
  }
  #section05 .timeWrap .colon {
    font-size: 11.48vw;
  }
  #section05 .timeWrap .time span {
    font-size: 1.95vw;
  }
  #section05 .mintButton {
    margin: 4.69vw auto 0;
  }
  #section05 .mintButton p,
  #section05 .mintButton a {
    font-size: 2.27vw;
  }
  #section06 {
    margin-top: 21.41vw;
  }
  #section06 .sec06_img01 {
    /* width: 26.3%; */
    width: 14.73vw;
    height: 40.92vw;
  }
  #section06 .sec06_img02 {
    /* width: 45.8%; */
    width: 28.19vw;
    height: 43.28vw;
    margin-left: 2.5vw;
  }
  #section07 {
    padding-top: 9.74vw;
  }
  #section07 .around_title {
    margin-bottom: 7.81vw;
  }
  #section07 .chatWrap {
    padding-left: 0;
  }
  #section07 .chatArea {
    justify-content: center;
    gap: 5.94vw;
    margin-bottom: 6.33vw;
  }
  #section07 .chatArea p {
    font-size: 1.88vw;
  }
  #section07 .sec7_cont {
    justify-content: center;
  }
  #section07 .sec7_cont .left {
    width: 21.8vw;
    margin-right: 5.31vw;
    padding-top: 0;
  }
  #section07 .sec7_cont .left .profile {
    padding: 3.59vw 0 0;
  }
  #section07 .sec7_cont .left .profileImg {
    width: 11.48vw;
  }
  #section07 .sec7_cont .left .profileCenter {
    margin: 0.39vw 0 3.44vw;
  }
  #section07 .sec7_cont .left .profileCenter h3 {
    font-size: 2.5vw;
  }
  #section07 .sec7_cont .left .profileCenter p {
    font-size: 1.64vw;
  }
  #section07 .sec7_cont .left .profileBottom p {
    font-size: 1.33vw;
  }
  #section07 .sec7_cont .left .profileBottom .address {
    font-size: 1.41vw;
  }
  #section07 .sec7_cont .left .profileBottom .button {
    margin-top: 2.42vw;
    font-size: 1.64vw;
  }
  #section07 .sec7_cont .left .smallProfile {
    margin: 2vw auto 0;
    width: 13.05vw;
    height: 3.67vw;
  }
  #section07 .sec7_cont .left .smallProfile.smallPf01 {
    margin-top: 3.2vw;
  }
  #section07 .sec7_cont .left .smallProfile .img {
    width: 2.8vw;
  }
  #section07 .sec7_cont .left .smallProfile p {
    font-size: 1.64vw;
  }

  #section07 .sec7_cont .right {
    width: 51.95vw;
  }
  #section07 .sec7_cont .chatCont .chatProfile .img {
    width: 6.41vw;
  }
  #section07 .sec7_cont .chatReceive .chatProfile {
    margin-right: 0;
  }
  #section07 .sec7_cont .chatReceive .info {
    padding-top: 1.5vw;
    margin-right: 1.8vw;
  }
  #section07 .sec7_cont .chatSub {
    padding: 2.73vw 2.53vw;
  }
  #section07 .sec7_cont .chatSub .chatImg {
    margin: 0;
  }
  #section07 .sec7_cont .chatSub p span {
    font-size: 1.64vw;
  }
  #section07 .sec7_cont .chatCont .chatProfile .name {
    font-size: 1.64vw;
  }
  #section07 .sec7_cont .chatCont .chatProfile .time {
    font-size: 1.48vw;
  }
  #section08 {
    padding-top: 10.55vw;
  }
  #section08 .svgWrap .areaNames p {
    font-size: 1.64vw;
  }
  #section08 .svgWrap {
    width: 43vw;
  }
  #section08 .sec8_title {
    font-size: 6.48vw;
  }
  #section09.pc {
    display: block;
  }
  #section10 {
    padding: 12.27vw 0 11.25vw;
  }
  #section10 .sec_inner {
    justify-content: flex-end;
  }
  #section10 .left {
    display: none;
  }
  #section10 .right {
    width: 80%;
  }
  #section10 .right h2 {
    font-size: 10.78vw;
  }
  #section10 .faqList {
    margin-top: 1.95vw;
  }
  #section10 .faqCont h3 {
    font-size: 2.03vw;
    padding-bottom: 0.94vw;
    margin-bottom: 1.64vw;
    margin-top: 3.13vw;
    line-height: 1.58;
  }
  #section10 .faqCont p {
    font-size: 1.56vw;
  }
  #section10 .faqImg {
    margin: 5.08vw 0 5px;
  }
  #section10 .contact h4 {
    font-size: 3.44vw;
  }
  #section10 .contact .btn a {
    font-size: 1.72vw;
    padding: 1.6vw 2.03vw;
  }
}

/* tablet*/
@media (max-width: 1000px) {
  .pc {
    display: none;
  }
  .tablet {
    display: none;
  }
  .tablet2 {
    display: block;
  }
  .mobile {
    display: block;
  }

  #main {
    height: 100vh;
    background: url(./img/m_main_background.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }
  #main .img_wrap {
    display: none;
  }
  #section01 .mainText {
    width: 100%;
  }
  #section01 .mainText h2 {
    font-size: 17.44vw;
    text-transform: uppercase;
    line-height: 1.2;
    min-height: 65vw;
  }
  #section01 .mainText h1 {
    display: none;
  }
  #section01 .mainText p {
    margin-top: 23.26vw;
    color: #d6d6d6;
    font-size: 3.72vw;
    line-height: 1.56;
    font-weight: 100;
    letter-spacing: -0.48px;
  }
  #section01 .mainText span {
    font-weight: 600;
  }

  #section03_m .imgWrap {
    position: relative;
    padding-top: 4vw;
  }

  #section03_m .peopleImg .people {
    z-index: 1;
  }
  #section03_m .peopleImg .shadow {
    top: 45%;
    left: 45%;
  }
  #section03_m h3 {
    margin: 4.37vw 0 2.5vw;
  }
  #section03_m .detailText {
    display: flex;
    position: relative;
    justify-content: space-around;
  }
  #section03_m .detailText::before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 48%;
    transform: translateX(-50%);
    background: rgba(112, 112, 112, 0.19);
  }
  #section03_m .detailText > div {
    width: 50%;
  }
  #section03_m .detailText > div:first-child {
    padding-right: 2%;
  }
  #section03_m .detailText > div:last-child {
    padding-left: 2%;
  }

  #section03_m .detailText h4 {
    font-size: 1.41vw;
    font-weight: 900;
    color: #fff;
  }
  #section03_m .detailText p {
    font-size: 1.72vw;
    color: #fff;
    font-weight: 100;
    line-height: 1.72;
    letter-spacing: -0.06vw;
    word-break: keep-all;
  }
  #section03_m .forceBg {
    width: 86%;
    left: 50%;
    transform: translateX(-50%);
  }
  #section03_m svg {
    margin-left: 6%;
  }
  #section04 {
    padding-bottom: 15vw;
  }
  #section05 .sect_bgText {
    font-size: 11.86vw;
  }

  #section05 .timeWrap {
    gap: 2.56vw;
  }
  #section05 .timeWrap .time {
    font-size: 12.33vw;
  }
  #section05 .timeWrap .time span {
    font-size: 1.63vw;
  }
  #section05 .timeWrap .colon {
    font-size: 11.4vw;
  }
  #section05 .mintButton p,
  #section05 .mintButton a {
    font-size: 4.19vw;
  }
  #section06 .imgBox {
    margin: 0;
    width: 100%;
  }
  #section06 .sec06_img01 {
    width: 28.84vw;
    height: 80.07vw;
    transform: translate(0);
    transform: translateY(3vw);
  }
  #section06 .sec06_img02 {
    width: 55.61vw;
    height: 80.07vw;
    transform: translateX(-3vw);
  }
  #section06 .mobileOnly {
    display: none !important;
  }
  #section09 .missionWrap {
    width: 84%;
    margin: 45px auto 0;
  }
}

/* mobile*/
@media (max-width: 500px) {
  #section06 .mobileOnly {
    display: block !important;
  }
  .pc {
    display: none;
  }
  .tablet {
    display: none;
  }
  .tablet2 {
    display: none;
  }
  .mobileOnly {
    display: block;
  }

  .sec_inner {
    width: 87%;
  }
  #section03_m .sec_inner {
    width: 100%;
  }
  #section03_m .detailText::before {
    display: none;
  }
  #section03_m .imgWrap {
    padding-top: 0;
    transform: translateY(-6vw);
  }
  #section03_m h3 {
    width: 86%;
    margin: 3vw auto 2.5vw;
  }
  #section03_m h3 img {
    height: 6.74vw;
  }
  #section03_m .detailText {
    width: 86%;
    margin: 0 auto;
    flex-flow: column;
  }
  #section03_m .detailText h4 {
    font-size: 4.19vw;
  }
  #section03_m .detailText p {
    font-size: 3.72vw;
  }
  #section03_m .detailText > div {
    width: 100%;
  }
  #section03_m .detailText > div:last-child {
    padding-left: 0;
    margin-top: 4.14vw;
  }

  #section03_m .peopleImg .people {
    top: 50%;
    left: 54%;
  }
  #section03_m .peopleImg .shadow {
    top: 50%;
    left: 50%;
  }
  #section03_m .atman .peopleImg {
    width: 82vw;
    top: 50%;
  }

  #section03_m .looper .peopleImg {
    width: 80vw;
  }

  #section03_m .hide .peopleImg {
    width: 90vw;
  }
  #section04 {
    padding-bottom: 12vw;
    background: url(./img/sec04_bg_m.png) no-repeat;
  }
  #section04 .text_wrap .num {
    font-size: 29.53vw;
  }
  #section04 h3,
  #section04 p {
    font-size: 3.72vw;
  }
  #section04 .section04_m_slide {
    margin-top: 26.98vw;
  }
  #section04 .img_wrap {
    display: none;
  }
  #section04 .slideImg {
    width: 40.88vw;
  }
  #section04 .slideImg.translate img {
    /* transform: translateY(-16.51vw); */
    margin-top: 16.51vw;
  }
  #section04 .slick-list {
    margin-right: -4.26vw;
  }
  #section04 .slick-slide {
    padding-right: 4.26vw;
  }
  #section05 .sec5_title h2 {
    font-size: 6.28vw;
  }

  #section05 .sect_bgText {
    font-size: 11.86vw;
  }
  #section05 .sec5_title p {
    font-size: 3.72vw;
  }
  #section05 .mintButton p,
  #section05 .mintButton a {
    font-size: 4.19vw;
  }
  #section05 .mintButton {
    margin: 6.05vw auto 0;
  }
  .around_title {
    flex-flow: column;
    margin-bottom: 15.12vw;
  }
  .around_title h2 {
    font-size: 4.19vw;
    width: 100%;
    letter-spacing: -0.03em;
  }
  .around_title p {
    font-size: 3.72vw;
    margin-top: 1.63vw;
  }
  #section06 .imgBox {
    display: none;
  }

  #section06 .m_imgBox {
    display: flex !important;
    justify-content: space-between;
  }

  #section06 .dots_custom {
    margin-top: 37vw;
  }
  #section06 .m_imgBox img:first-child {
    width: 33.5%;
  }
  #section06 .m_imgBox img:last-child {
    width: 64%;
  }
  #section06 .bigTxt {
    font-size: 16.51vw;
    left: 0;
    bottom: 9vw;
    line-height: 90%;
    pointer-events: none;
  }
  #section06 .bigTxt span {
    -webkit-text-stroke: 0.005em #fff;
  }
  #section07 {
    padding-top: 29.28vw;
  }
  #section07 .chatArea {
    flex-wrap: wrap;
    gap: 2.79vw 2.09vw;
    width: 80%;
    margin: 3.02vw auto;
  }
  #section07 .chatArea p {
    font-size: 3.26vw;
    padding: 0.47vw 3.4vw;
    border: 1px solid #1f1f1f;
    border-radius: 3em;
    opacity: 1;
    color: rgba(255, 255, 255, 0.21);
  }
  #section07 .chatArea p.on {
    background: #656565;
    color: #fff;
    font-weight: 600;
  }
  #section07 .sec7_cont {
    flex-wrap: wrap;
  }
  #section07 .sec7_cont .left {
    padding-top: 9.07vw;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  #section07 .sec7_cont .left .profile {
    padding: 12.09vw 0 0;
    width: 33.72vw;
  }
  #section07 .sec7_cont .left .smallProfileWrap {
    margin: 0;
    margin-left: 4.19vw;
  }
  #section07 .sec7_cont .left .profileImg {
    width: 17.91vw;
    position: absolute;
    top: -8.84vw;
    left: 50%;
    transform: translateX(-50%);
  }
  #section07 .sec7_cont .left .profileCenter h3 {
    font-size: 3.49vw;
  }
  #section07 .sec7_cont .left .profileCenter h3 span::before {
    width: 1.4vw;
    height: 1.4vw;
  }
  #section07 .sec7_cont .left .profile::before {
    display: none;
  }
  #section07 .sec7_cont .left .smallProfile {
    width: 20vw;
    height: auto;
    padding: 0 1vw;
    height: 7vw;
    margin: 1.86vw auto 0;
  }
  #section07 .sec7_cont .left .smallProfile .img {
    width: auto;
    height: 80%;
    margin-right: 1vw;
  }

  #section07 .sec7_cont .left .smallProfile .img img {
    width: auto;
    height: 100%;
  }
  #section07 .sec7_cont .left .smallProfile p {
    font-size: 2.33vw;
  }
  #section07 .sec7_cont .left .profileCenter p {
    font-size: 2.33vw;
  }
  #section07 .sec7_cont .left .profileBottom .address {
    font-size: 2.09vw;
  }
  #section07 .sec7_cont .left .profileBottom p {
    font-size: 1.63vw;
  }
  #section07 .sec7_cont .left .profileBottom .icon {
    width: 2.56vw;
  }
  #section07 .sec7_cont .right {
    width: 100%;
    margin-top: 4.19vw;
  }
  #section07 .sec7_cont .chatCont {
    width: 100%;
  }
  #section07 .sec7_cont .chatSub {
    padding: 4.88vw 4.42vw;
  }
  #section07 .sec7_cont .chatCont .chatProfile .img {
    width: 14.19vw;
  }
  #section07 .sec7_cont .chatCont .chatProfile .name {
    font-size: 2.79vw;
  }
  #section07 .sec7_cont .chatCont .chatProfile .time {
    font-size: 2.56vw;
  }
  #section07 .sec7_cont .chatSub .chatImg {
    width: 77%;
  }
  #section07 .sec7_cont .chatSub p {
    margin: 4.42vw 0;
  }
  #section07 .sec7_cont .chatSub p span {
    font-size: 3.2vw;
  }
  #section07 .sec7_cont .chatCont.chatSend {
    margin-left: 0;
  }
  #section08 {
    padding-top: 25vw;
    margin: 0;
  }
  #section08 .sec8_textWrap {
    position: relative;
    top: auto;
    left: auto;
    transform: inherit;
  }
  #section08 .sec8_title {
    font-size: 9.07vw;
  }
  #section08 .svgWrap {
    width: 100%;
    float: inherit;
  }
  #section08 .sec8_textWrap .sec8_btn {
    margin-top: 3.72vw;
    height: 9.37vw;
    line-height: 9.37vw;
  }
  #section08 .sec8_textWrap .sec8_btn .text {
    font-size: 3.49vw;
    width: 29.09vw;
  }
  #section08 .sec8_textWrap .sec8_btn .arrow {
    width: 9.37vw;
  }
  #section08 .sec8_textWrap .sec8_btn .arrow img {
    width: 3.44vw;
  }
  #section08 .sec8_textWrap .sec8_btn .img {
    width: 23.49vw;
  }
  #section08 .svgWrap {
    margin-top: 8.07vw;
  }
  #section08 .svgWrap .areaNames p {
    font-size: 3.49vw;
  }
  #section08 .svgWrap .areaNames p.fra {
    right: 0;
  }
  #section08 .svgWrap .areaNames p.kor {
    top: 45vw;
    right: 3.63vw;
  }
  #section08 .svgWrap .areaNames p.areas {
    top: 34.37vw;
  }
  #section08 .svgWrap .areaNames p.areas2 {
    top: 67.4vw;
    left: 21.14vw;
  }
  #section09.pc {
    display: none;
  }
  #section09_m {
    padding: 14.07vw 0 60vw;
    background: #191919;
    position: relative;
    overflow: hidden;
  }
  #section09_m .missionTitle {
    text-align: center;
    margin-bottom: 14vw;
  }
  #section09_m .missionTitle h2 {
    font-size: 10.93vw;
    color: #fff;
    width: 100%;
  }
  #section09_m .missionTitle p {
    font-size: 5.12vw;
    color: #727272;
    margin-top: 2.33vw;
    font-weight: 100;
  }

  #section09_m .missionBox {
    padding: 3.49vw 2.47vw;
    background: #fff;
    width: 90%;
    border-radius: 0.5em;
    margin-bottom: 3.95vw;
    height: 33vw;
  }
  #section09_m .missionBox .num {
    font-size: 3.26vw;
    color: rgba(68, 68, 68, 0.37);
  }
  #section09_m .missionBox .title {
    font-size: 4.88vw;
    color: #444;
    letter-spacing: -0.04em;
    font-weight: 600;
    text-transform: uppercase;
  }

  #section09_m .missionBox .text {
    font-size: 3.49vw;
    font-weight: 300;
    color: #444;
    margin-top: 0.98vw;
  }

  #section09_m .missionBottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  #section09_m .missionBottom p {
    font-size: 25.81vw;
    font-weight: 900;
    color: transparent;
    background: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 1px #fff;
    text-transform: uppercase;
    white-space: nowrap;
    will-change: transform;
    letter-spacing: -5px;
    opacity: 0.25;
  }
  #section09_m .missionBottom p.btText01 {
    animation: sec9Marquee 30s linear infinite;
  }
  #section09_m .missionBottom p.btText02 {
    animation: sec9Marquee2 30s linear infinite;
    margin-top: -5vw;
  }
  #section10 {
    padding: 21.37vw 0 0;
  }
  #section10 .sec_inner {
    justify-content: flex-start;
  }
  #section10 .right {
    width: 100%;
  }
  #section10 .right h2 {
    font-size: 21.86vw;
    line-height: 0.9;
  }
  #section10 .faqList {
    margin-top: 11.16vw;
  }
  #section10 .faqCont h3 {
    font-size: 3.72vw;
    padding-bottom: 3vw;
    margin-bottom: 3.5vw;
  }
  #section10 .faqCont p {
    font-size: 3.72vw;
    word-break: keep-all;
  }
  #section10 .faqCont p br {
    display: none;
  }
  #section10 .faqImg {
    margin: 12.79vw 0 9.77vw;
  }
  #section10 .contact {
    width: 100%;
    margin-top: 15.93vw;
    margin-bottom: 14vw;
  }
  #section10 .contact h4 {
    font-size: 6.51vw;
    margin-bottom: 5.47vw;
  }
  #section10 .contact .btns {
    width: 100%;
    gap: 1.4vw;
  }
  #section10 .contact .btn a {
    font-size: 3.49vw;
    padding: 2.56vw 3.95vw;
  }
  #footer .copy {
    font-size: 4.65vw !important;
  }
}
/* @media screen and (aspect-ratio: 4/3) {
  } */
