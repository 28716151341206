.carousel-container {
  width: 300px;
  height: 200px;
  position: relative;
  overflow: hidden;
  perspective: 1000px; /* Provides depth perception for the rotation */
}

.carousel-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transform: rotateY(180deg) scale(0.5);
  transform-style: preserve-3d; /* Ensures 3D transformations are preserved */
  transition: transform 1s;
  backface-visibility: hidden; /* Hides the "back" side of the element during rotation */
}

.carousel-image.active {
  transform: rotateY(0deg) scale(1);
}

.carousel-image2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;

  opacity: 0;
  transition: all 1.5s ease;
  transform: scale(0);
}
.carousel-image2.active {
  opacity: 1;
  transform: scale(1);
}
.parentDiv {
  transform-style: preserve-3d;
  perspective: 700px;
}
.imgBox {
  transform-style: preserve-3d;
  perspective: 10000px;
  transform: translateX(5%);
}

.sec06_img02 {
  z-index: 10;
}
