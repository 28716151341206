#footer {
  padding: 87px 0;
  background: #191919;
}

#footer .copy {
  font-size: 20px;
  color: #888;
  letter-spacing: -0.01em;
  font-weight: 100;
  text-align: center;
}
#footer .icons {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  gap: 9px;
}
#footer .icons img {
  width: 40px;
}

#footer button {
  background: transparent;
  border: none;
}
