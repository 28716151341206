body {
  background: #101010;
}
img {
  width: 100%;
}

.font2 {
  font-family: "Bai Jamjuree", sans-serif;
}
.font3 {
  font-family: "Futura";
}
.sec_inner {
  width: 83%;
  margin: 0 auto;
}
.height100 {
  height: 100vh;
  position: relative;
}
.height100 .sec_inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#main {
  width: 100%;
  height: auto;
  /* background: url(./img/test.jpg) no-repeat; */
  background-size: 100% auto;
  background-position: top;
  position: relative;
  overflow: hidden;
}

#main .blackBg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #191919 100%); */
  z-index: 6;
  /* background: rgba(0, 0, 0, 0.3); */
  background: rgb(16 16 16);
}
#main .blackBg2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(179deg, rgba(16, 16, 16, 0) -34%, #101010 90%);
  z-index: 6;
}

#main .img_wrap {
  position: absolute;
  width: 100%;
  height: 100vh;
}
#main .img_wrap img {
  position: absolute;
  object-fit: contain;
}
#main .wallBg {
  position: absolute;
  width: 110%;
  height: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: url(./img/m_main_background.jpg) no-repeat;
  background-size: cover;
  display: none;
}
#main .img_wrap .wall {
  /* width: 100%; */
  width: 110%;
  /* bottom: 0; */
  top: 60%;
  left: 50%;
  /* transform: translateX(-50%); */
  transform: translate(-50%, -50%);
}
#main .img_wrap .move_img {
  width: 120%;
  left: -10%;
  top: -10%;
}

#section01 {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 10;
}
#section01 .mainText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
}
#section01 .mainText h2 {
  /* font-size: 75px; */
  font-size: 3.91vw;
  color: #fff;
  font-weight: 500;
  letter-spacing: -2.25px;
}
#section01 .mainText h1 {
  width: 49vw;
}

#section02 {
  /* padding: 250px 0 90px; */
  padding: 13.02vw 0 4.69vw;
  position: relative;
  z-index: 6;
}
#section02 p {
  font-size: 25px;
  /* font-size: 1.3vw; */
  color: #d6d6d6;
  text-align: center;
  line-height: 1.46;
  font-weight: 100;
  letter-spacing: -1.11px;
}
#section02 p span.bold {
  font-weight: 500;
}

#section03 {
  /*
    background: #191919; */
  /* margin-top: 200px; */
  margin-top: 10.42vw;
  height: 400vh;
  position: relative;
}

#section03 .forceWrap {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
}
#section03 .force {
  position: absolute;
  width: 100%;
  height: 100vh;
  /* backgro
    und: red; */
  z-index: 1;
  top: 0;
  left: 0;
}
#section03 .force_bg {
  width: 80%;
  position: absolute;
  /* top: 0; */
  top: 34%;
  left: 50%;
  /* transform: translateX(-50%); */
  transform: translate(-50%, -50%);
}
#section03 .text_wrap {
  width: 100%;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.58;
}
.wrap {
  width: 100%;
  height: 100vh;
  position: relative;
}
#section03 .peopleImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#section03 .peopleImg .people {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
#section03 .peopleImg .shadow {
  width: 100%;
  position: absolute;
  /* left: -40px;
  top: -5px; */
  left: -5.8%;
  top: -0.5%;
  z-index: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
#section03 .looper .peopleImg .shadow {
  left: 5%;
}
#section03 .hide .peopleImg .shadow {
  left: 7%;
  top: 3.5%;
}

#section03 .atman .peopleImg {
  /* width: 675px; */
  width: 35.16vw;
  top: 51%;
}
#section03 .looper .peopleImg {
  /* width: 658px; */
  width: 34.27vw;
  top: 54%;
}
#section03 .hide .peopleImg {
  /* width: 720.3px; */
  width: 37.52vw;
  top: 51%;
}

#section03 .storyWrap {
  position: absolute;
  left: 38%;
  top: 17.5%;
  background-color: rosybrown;
  width: 52.5%;
}
#section03 .storyWrap .circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4vw;
  left: 0;
}
#section03 .hide .storyWrap .circle {
  top: 4.2vw;
  left: 3.5vw;
}
#section03 .storyWrap .circle::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #fff;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
#section03 .storyWrap .circle::after {
  content: "";
  width: 11px;
  height: 11px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#section03 .storyWrap .lineWrap {
  position: absolute;
  left: 0.8vw;
  top: 4.2vw;
}
#section03 .hide .storyWrap .lineWrap {
  left: 3.9vw;
  top: 4.5vw;
}
#section03 .storyWrap .line {
  /* width: 13vw; */
  height: 1px;
  background: #fff;
  transform: rotate(-15.5deg);
  transform-origin: left center;
}
#section03 .hide .storyWrap .line {
  transform: rotate(-21.5deg);
  /* width: 10vw; */
}
#section03 .storyWrap .line2 {
  /* width: 250px; */
  width: 13.02vw;
  position: absolute;
  top: 0;
  left: 13vw;
  overflow: hidden;
}
#section03 .storyWrap .box {
  /* width: 753px;
  height: 560px; */
  position: absolute;
  left: 13vw;
  top: 1.5vw;
  /* padding: 35px; */
  padding: 1.82vw;
}
#section03 .storyWrap .box::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(36.8px);
  backdrop-filter: blur(36.8px);
}
#section03 .storyWrap .box > * {
  position: relative;
  z-index: 1;
}
#section03 .storyWrap .storyLine {
  /* width: 190px; */
  width: 9.9vw;
}
#section03 .storyWrap .storyLine.right {
  float: right;
}
#section03 .storyWrap .box h3 {
  /* margin-top: 30px; */
  margin-top: 1.56vw;
  /* width: 360px; */
  width: 18.75vw;
  /* margin-bottom: 50px; */
  margin-bottom: 2.6vw;
}
#section03 .storyWrap .box .txt {
  /* margin-bottom: 50px; */
  margin-bottom: 2.6vw;
  color: #fff;
  /* font-size: 23px; */
  font-size: 1.2vw;
  letter-spacing: -0.01em;
}
#section03 .storyWrap .box .txt h4 {
  font-weight: 900;
  margin-bottom: 5px;
}
#section03 .storyWrap .box .txt p {
  font-weight: 100;
  line-height: 1.3;
}

/* section03 animation */

#section03 .peopleImg.active img {
  transform: translateX(-45%);
  opacity: 1;
  /* transition: transform 1s cubic-bezier(0.69, -0.7, 0.34, 1.8),
    opacity 1.1s ease-in-out; */
  transition: transform 1.1s ease-in-out, opacity 1.1s ease-in-out;
}
#section03 .peopleImg.active img.shadow {
  /* transition: transform 1s cubic-bezier(0.68, -0.6, 0.32, 1.6); */
  transition: transform 1s ease-in-out;
}

#section03 .peopleImg img {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
/* #section03 .peopleImg.peopleOn img {
  opacity: 1;
  transition: opacity 1s ease-in-out 0.2s, transform 1s ease-in-out 0.2s;
} */
#section03 .peopleImg.popleOn.active img {
  opacity: 1;
  transition: transform 1.1s ease-in-out, opacity 1.1s ease-in-out;
}

#section03 .peopleImg.active img {
  transform: translateX(-45%);
  opacity: 1;
  /* transition: transform 1s cubic-bezier(0.69, -0.7, 0.34, 1.8),
    opacity 1.1s ease-in-out; */
  transition: transform 1.1s ease-in-out, opacity 1.1s ease-in-out;
}
#section03 .peopleImg.active img.shadow {
  /* transition: transform 1s cubic-bezier(0.68, -0.6, 0.32, 1.6); */
  transition: transform 1s ease-in-out;
}

#section03 .storyWrap .circle::before {
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease;
}
#section03 .storyWrap .circle::after {
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: all 0.3s ease;
}
#section03 .storyWrap.active .circle::before {
  transform: scale(1);
  opacity: 1;
  transition: all 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.6s;
}
#section03 .storyWrap.active .circle::after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  transition: all 0.7s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.5s;
}
#section03 .storyWrap .line {
  width: 0vw;
  transition: width 0.3s ease;
}
#section03 .storyWrap.active .line {
  width: 13vw;
  transition: width 0.5s ease-in-out 1s;
}
#section03 .hide .storyWrap.active .line {
  width: 10vw;
}
#section03 .storyWrap .line2 img {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: relative;
}
#section03 .storyWrap.active .line2 img {
  transform: translateX(0%);
  opacity: 1;
  transition: transform 0.7s ease-in-out 1.1s, opacity 0.7s ease-in-out 1.1s;
}
#section03 .storyWrap .box::before {
  transform: scale(0.01);
  transition: transform 0.3s ease;
}
#section03 .storyWrap.active .box::before {
  transform: scale(1);
  transition: all 0.5s cubic-bezier(0.85, 0, 0.15, 1) 1.3s;
}
#section03 .storyWrap .storyLine {
  opacity: 0;
  transition: opacity 0.3s ease;
}
#section03 .storyWrap.active .storyLine {
  opacity: 1;
  transition: opacity 2s ease 1.4s;
}
#section03 .storyWrap.active .storyLine:nth-of-type(2) {
  transition: opacity 2s ease 1.5s;
}
#section03 .storyWrap .box h3 {
  opacity: 0;
  transition: opacity 0.3s ease;
}
#section03 .storyWrap.active .box h3 {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 1.5s;
}
#section03 .storyWrap .box .txt h4,
#section03 .storyWrap .box .txt p {
  overflow: hidden;
}
#section03 .storyWrap .box .txt h4 span,
#section03 .storyWrap .box .txt p span {
  display: inline-block;
  position: relative;
  transform: translateY(80%);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
#section03 .storyWrap.active .box .txt h4 span,
#section03 .storyWrap.active .box .txt p span {
  transform: translateY(0%);
  opacity: 1;
}
#section03 .storyWrap.active .box .txt:nth-of-type(1) h4 span {
  transition: opacity 0.5s ease-in-out 1.7s, transform 0.5s ease-in-out 1.7s;
}
#section03 .storyWrap.active .box .txt:nth-of-type(1) p span {
  transition: opacity 0.5s ease-in-out 1.8s, transform 0.5s ease-in-out 1.8s;
}
#section03 .storyWrap.active .box .txt:nth-of-type(2) h4 span {
  transition: opacity 0.5s ease-in-out 1.9s, transform 0.5s ease-in-out 1.9s;
}
#section03 .storyWrap.active .box .txt:nth-of-type(2) p span {
  transition: opacity 0.5s ease-in-out 2s, transform 0.5s ease-in-out 2s;
}

#section03 .peopleImg.remove img {
  transform: translateX(-150%);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
#section03 .storyWrap.remove .circle::before {
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s ease-in-out 0.6s;
}
#section03 .storyWrap.remove .circle::after {
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: all 0.3s ease-in-out 0.5s;
}
#section03 .storyWrap.remove .line {
  width: 0vw;
  transition: width 0.3s ease-in-out 0.4s;
}
#section03 .storyWrap.remove .line2 img {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out 0.3s, opacity 0.3s ease-in-out 0.3s;
}
#section03 .storyWrap.remove .box::before {
  transform: scale(0.01);
  transition: transform 0.3s ease-in-out 0.3s;
}
#section03 .storyWrap.remove .box h3,
#section03 .storyWrap.remove .box .storyLine,
#section03 .storyWrap.remove .box .txt:nth-of-type(1) h4 span,
#section03 .storyWrap.remove .box .txt:nth-of-type(1) p span,
#section03 .storyWrap.remove .box .txt:nth-of-type(2) h4 span,
#section03 .storyWrap.remove .box .txt:nth-of-type(2) p span {
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.2s;
}

#section03.atman .text_wrap text {
  fill: transparent;
  stroke: #d15eb1;
}

#section03.atman .text_wrap text.t25,
#section03.atman .text_wrap text.t20,
#section03.atman .text_wrap .text_wrap text.t24 {
  animation: atmanColor01 3s steps(1, start) infinite;
}
#section03.atman .text_wrap text.t22,
#section03.atman .text_wrap text.t26,
#section03.atman .text_wrap text.t21 {
  animation: atmanColor02 3s steps(1, start) infinite;
}
#section03.atman .text_wrap text.t19,
#section03.atman .text_wrap text.t23,
#section03.atman .text_wrap text.t27 {
  animation: atmanColor03 3s steps(1, start) infinite;
}

#section03.looper .text_wrap text {
  fill: transparent;
  stroke: #78e58f;
}
#section03.looper .text_wrap text.t25,
#section03.looper .text_wrap text.t20,
#section03.looper .text_wrap .text_wrap text.t24 {
  animation: looperColor01 3s steps(1, start) infinite;
}
#section03.looper .text_wrap text.t22,
#section03.looper .text_wrap text.t26,
#section03.looper .text_wrap text.t21 {
  animation: looperColor02 3s steps(1, start) infinite;
}
#section03.looper .text_wrap text.t19,
#section03.looper .text_wrap text.t23,
#section03.looper .text_wrap text.t27 {
  animation: looperColor03 3s steps(1, start) infinite;
}

#section03.hide .text_wrap text {
  fill: transparent;
  stroke: #5050eb;
}
#section03.hide .text_wrap text.t25,
#section03.hide .text_wrap text.t20,
#section03.hide .text_wrap .text_wrap text.t24 {
  animation: hideColor01 3s steps(1, start) infinite;
}
#section03.hide .text_wrap text.t22,
#section03.hide .text_wrap text.t26,
#section03.hide .text_wrap text.t21 {
  animation: hideColor02 3s steps(1, start) infinite;
}
#section03.hide .text_wrap text.t19,
#section03.hide .text_wrap text.t23,
#section03.hide .text_wrap text.t27 {
  animation: hideColor03 3s steps(1, start) infinite;
}

@keyframes atmanColor01 {
  0%,
  66%,
  100% {
    fill: transparent;
    stroke: #d15eb1;
  }
  33% {
    fill: #d15eb1;
    stroke: none;
  }
}
@-webkit-keyframes atmanColor01 {
  0%,
  66%,
  100% {
    fill: transparent;
    stroke: #d15eb1;
  }
  33% {
    fill: #d15eb1;
    stroke: none;
  }
}
@keyframes atmanColor02 {
  0%,
  33%,
  100% {
    fill: transparent;
    stroke: #d15eb1;
  }
  66% {
    fill: #d15eb1;
    stroke: none;
  }
}
@-webkit-keyframes atmanColor02 {
  0%,
  33%,
  100% {
    fill: transparent;
    stroke: #d15eb1;
  }
  66% {
    fill: #d15eb1;
    stroke: none;
  }
}

@keyframes atmanColor03 {
  0%,
  33%,
  66% {
    fill: transparent;
    stroke: #d15eb1;
  }
  100% {
    fill: #d15eb1;
    stroke: none;
  }
}
@-webkit-keyframes atmanColor03 {
  0%,
  33%,
  66% {
    fill: transparent;
    stroke: #d15eb1;
  }
  100% {
    fill: #d15eb1;
    stroke: none;
  }
}

@keyframes looperColor01 {
  0%,
  66%,
  100% {
    fill: transparent;
    stroke: #78e58f;
  }
  33% {
    fill: #78e58f;
    stroke: none;
  }
}
@-webkit-keyframes looperColor01 {
  0%,
  66%,
  100% {
    fill: transparent;
    stroke: #78e58f;
  }
  33% {
    fill: #78e58f;
    stroke: none;
  }
}
@keyframes looperColor02 {
  0%,
  33%,
  100% {
    fill: transparent;
    stroke: #78e58f;
  }
  66% {
    fill: #78e58f;
    stroke: none;
  }
}
@-webkit-keyframes looperColor02 {
  0%,
  33%,
  100% {
    fill: transparent;
    stroke: #78e58f;
  }
  66% {
    fill: #78e58f;
    stroke: none;
  }
}

@keyframes looperColor03 {
  0%,
  33%,
  66% {
    fill: transparent;
    stroke: #78e58f;
  }
  100% {
    fill: #78e58f;
    stroke: none;
  }
}
@-webkit-keyframes looperColor03 {
  0%,
  33%,
  66% {
    fill: transparent;
    stroke: #78e58f;
  }
  100% {
    fill: #78e58f;
    stroke: none;
  }
}

@keyframes hideColor01 {
  0%,
  66%,
  100% {
    fill: transparent;
    stroke: #5050eb;
  }
  33% {
    fill: #5050eb;
    stroke: none;
  }
}
@-webkit-keyframes hideColor01 {
  0%,
  66%,
  100% {
    fill: transparent;
    stroke: #5050eb;
  }
  33% {
    fill: #5050eb;
    stroke: none;
  }
}
@keyframes hideColor02 {
  0%,
  33%,
  100% {
    fill: transparent;
    stroke: #5050eb;
  }
  66% {
    fill: #5050eb;
    stroke: none;
  }
}
@-webkit-keyframes hideColor02 {
  0%,
  33%,
  100% {
    fill: transparent;
    stroke: #5050eb;
  }
  66% {
    fill: #5050eb;
    stroke: none;
  }
}

@keyframes hideColor03 {
  0%,
  33%,
  66% {
    fill: transparent;
    stroke: #5050eb;
  }
  100% {
    fill: #5050eb;
    stroke: none;
  }
}
@-webkit-keyframes hideColor03 {
  0%,
  33%,
  66% {
    fill: transparent;
    stroke: #5050eb;
  }
  100% {
    fill: #5050eb;
    stroke: none;
  }
}

#section04 {
  background: url(./img/sec04_bg.png) no-repeat;
  background-size: cover;
  position: relative;
  /* height: 150vh; */
  height: 130vh;
  background-position: center;
  background-attachment: fixed;
  margin-top: 115px;
  padding-top: 50px;
}
/* 
#section04 .blackBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 53.5vh;
  background: linear-gradient(1deg, rgba(16, 16, 16, 0) -31%, #101010 89%);
}
#section04 .blackBg2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20.9vh;
  background: linear-gradient(179deg, rgba(16, 16, 16, 0) -31%, #101010 89%);
} */
#section04 .sec_inner {
  position: sticky;
  z-index: 1;
  height: 100vh;
  /* top: 90px; */
  top: 4.69vw;
  left: 0;
}
#section04 .text_wrap {
  /* padding-top: 45px; */
  padding-top: 2.34vw;
}
#section04 .num {
  font-family: "Bai Jamjuree", sans-serif;
  /* font-size: 248px; */
  font-size: 12.92vw;
  font-weight: 900;
  font-style: italic;
  /* letter-spacing: -7.74px; */
  letter-spacing: -0.4vw;
  color: #fff;
}
#section04 h3 {
  /* font-size: 25px; */
  font-size: 1.3vw;
  font-weight: bold;
  line-height: 1.4;
  /* letter-spacing: -0.75px; */
  letter-spacing: -0.04vw;
  color: #fff;
}
#section04 p {
  /* font-size: 25px; */
  font-size: 1.3vw;
  font-weight: 100;
  color: #fff;
  line-height: 1.4;
  /* letter-spacing: -0.75px; */
  letter-spacing: -0.04vw;
  display: inline-block;
  position: relative;
  margin-top: 36px;
  padding-top: 36px;
}
#section04 p .forceName {
  font-weight: bold;
}
#section04 p::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #707070;
  position: absolute;
  top: 0;
  left: 0;
}
#section04 .img_wrap {
  position: absolute;
  /* top: 120px; */
  top: 10.25vw;
  right: 8.5%;
  display: flex;
  justify-content: flex-end;
  /* gap: 35px; */
  gap: 1.82vw;
  z-index: 2;
}
#section04 .img_wrap .imgBox {
  /* width: 240px; */
  width: 12.5vw;
}
#section04 .img_wrap .imgOne {
  transform: translateY(112px);
}
#section04 .img_wrap .imgTwo {
  transform: translateY(212px);
}
#section04 .img_wrap .img04 {
  margin-top: 41px;
}
#section04 .img_wrap .img05 {
  margin-top: 51px;
}
#section04 .img_wrap .img06 {
  margin-top: 49px;
}

#section05 .sec5_title h2 {
  font-size: 55px;

  color: #fff;
  text-align: center;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: -1.65px;
}
#section05 .sec5_title p {
  /* font-size: 26px; */
  font-size: 1.35vw;
  margin: 35px 0 30px;
  color: rgba(255, 255, 255, 0.67);
  letter-spacing: -0.78px;
  text-align: center;
  font-weight: 200;
}
#section05 .timeWrap {
  display: flex;
  justify-content: center;
  gap: 38px;
}
#section05 .timeWrap .time {
  /* font-size: 161px; */
  font-size: 8.39vw;
  color: rgba(255, 255, 255, 0.64);
  /* letter-spacing: -4.83px; */
  letter-spacing: -0.25vw;
  font-weight: bold;
  font-style: italic;
}
#section05 .timeWrap .time span {
  /* font-size: 26px; */
  font-size: 1.35vw;
  font-weight: 500;
  letter-spacing: -0.78px;
  color: rgba(255, 255, 255, 0.15);
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-style: normal;
}
#section05 .timeWrap .colon {
  /* font-size: 151px; */
  font-size: 7.86vw;
  color: rgba(255, 255, 255, 0.42);
  /* letter-spacing: -4.53px; */
  letter-spacing: -0.24vw;
  font-weight: 500;
}
#section05 .countEnd {
  color: #fff;
}
#section05 .mintButton {
  margin: 47px auto 0;
  text-align: center;
}
#section05 .mintButton p,
#section05 .mintButton a {
  font-size: 25px;
  color: #d6d6d6;
  padding: 3px 18px;
  font-weight: 500;
  letter-spacing: -0.75px;
  background: rgba(80, 80, 80, 0.13);
  border-radius: 22.5px;
  display: inline-block;
}
.around_title {
  margin-bottom: 130px;
}
.around_title > * {
  float: left;
}
.around_title h2 {
  font-size: 29px;
  color: #fff;

  font-weight: bold;
  letter-spacing: -0.87px;
  text-transform: uppercase;
  -webkit-text-stroke: 1px #fff;
  /* width: 370px; */
  /* width: 19.27vw; */
  width: 26%;
}
.around_title p {
  /* font-size: 26px; */
  font-size: 1.35vw;
  color: #fff;
  letter-spacing: -0.78px;
  line-height: 1.35;
  font-weight: 100;
}
#section06 {
  overflow: hidden;
}
#section06 .sec_inner {
  position: relative;
  transform-style: preserve-3d;
  perspective: 3000px;
  perspective-origin: center;
}
#section06 .around_title {
  display: flex;
}
#section06 .bigTxt {
  /* font-size: 177px; */
  font-size: 10vw;
  position: absolute;
  bottom: 3vw;
  right: 0;
  line-height: 0.78;
  z-index: 10;
}
#section06 .bigTxt > span {
  display: block;
  -webkit-text-stroke: 1px #fff;
  color: #fff;
  /* letter-spacing: -6.69px; */
  letter-spacing: -0.35vw;
  font-weight: 900;
  text-transform: uppercase;
  overflow: hidden;
}

#section06 .bigTxt .lineTxt {
  color: transparent;
  background: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  z-index: 2;
  position: relative;
}
#section06 .imgBox {
  display: flex;
  width: 45%;
  margin-left: 2vw;
}
#section06 .imgBox img {
  width: 100%;
}
#section06 .sec06_img01 {
  /* width: 282.9px; */
  /* width: 19.7%; */
  width: 233px;
  transform: translateY(-78.5px);
}
#section06 .sec06_img02 {
  /* width: 37.7%;
  height: 831px; */
  width: 450px;
  height: 691px;
  position: relative;
  perspective: 1000px;
  transform: translateX(-2vw);
}

#section06 .sec06_img02 span {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
}

#section06 .sec06_img02 span:nth-child(1) {
  background-image: url(./img/sec06_img2-1.png);
}

#section06 .sec06_img02 span:nth-child(2) {
  background-image: url(./img/sec06_img2-2.png);
}

#section06 .sec06_img02 span:nth-child(3) {
  background-image: url(./img/sec8_one_img_looper.png);
}

#section06 .imgBox .test {
  transition: all 1s ease;
}
#section06 .Atman .sec06_img02 span:nth-child(2),
#section06 .Atman .sec06_img02 span:nth-child(3) {
  visibility: hidden;
}

@keyframes test {
  0% {
    transform: rotateY(6000deg) rotateX(2deg) scale(0.8);
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg) scale(1);
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg) scale(1);
  }
}
@keyframes test2 {
  0% {
    transform: rotateY(180deg) rotateX(2deg) scale(0.8);
  }
  100% {
    transform: rotateY(0deg) rotateX(0deg) scale(1);
  }
}
#section07 {
  padding-top: 140px;
}
#section07 .chatWrap {
  /* padding-left: 370px; */
  padding-left: 21.4%;
  position: relative;
}
#section07 .chatWrap .chatBg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  filter: blur(11.8px);
  -webkit-filter: blur(11.8px);
}
#section07 .chatArea {
  display: flex;
  /* gap: 60px; */
  gap: 5.55%;
  margin-bottom: 65px;
}
#section07 .chatArea p {
  font-size: 21px;
  font-weight: 100;
  color: #fff;
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -0.63px;
  opacity: 0.21;
  cursor: pointer;
}
#section07 .chatArea p.on {
  opacity: 1;
  font-weight: 500;
}
#section07 .around_title {
  margin-bottom: 89px;
}
#section07 .sec7_cont {
  display: flex;
  justify-content: space-between;
}
#section07 .sec7_cont .left {
  /* width: 335px; */
  width: 25%;
  padding-top: 50px;
}
#section07 .sec7_cont .left .profile {
  width: 100%;
  padding: 58px 0 0;
  background: rgba(54, 54, 54, 0.25);
  text-align: center;
  border-radius: 15px;

  position: relative;
}
#section07 .sec7_cont .left .profile::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #212121;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
#section07 .sec7_cont .left .profileImg {
  /* width: 140px; */
  width: 8.1vw;
  margin: 0 auto;
}
#section07 .sec7_cont .left .profileCenter {
  color: #fff;
  margin: 15px 0 47px;
}
#section07 .sec7_cont .left .profileCenter h3 {
  /* font-size: 32px; */
  font-size: 1.85vw;
  letter-spacing: -0.96px;
}
#section07 .sec7_cont .left .profileCenter h3 span {
  display: inline-block;
  position: relative;
}
#section07 .sec7_cont .left .profileCenter h3 span::before {
  content: "";
  width: 9px;
  height: 9px;
  position: absolute;
  background: #29e688;
  top: 0;
  right: -15px;
  border-radius: 50%;
}
#section07 .sec7_cont .left .profileCenter p {
  /* font-size: 21px; */
  font-size: 1.2vw;
  color: #797979;
  font-weight: 500;
  letter-spacing: -0.63px;
}
#section07 .sec7_cont .left .profileBottom p {
  /* font-size: 18px; */
  font-size: 1.05vw;
  color: #f0f0f0;
  display: flex;
  justify-content: center;
  letter-spacing: -0.54px;
}
#section07 .sec7_cont .left .profileBottom .address {
  margin-bottom: 13px;
}
#section07 .sec7_cont .left .profileBottom .icon {
  width: 19px;
  margin-right: 5px;
}
#section07 .sec7_cont .left .profileBottom .button {
  margin-top: 49px;
  width: 100%;
  padding: 10px 0;
  background: rgba(54, 54, 54, 0.25);
  color: #797979;
  /* font-size: 21px; */
  font-size: 1.2vw;
  letter-spacing: -0.63px;
  border-radius: 0 0 15px 15px;
}
#section07 .sec7_cont .left .smallProfileWrap {
  margin: 37px auto 0;
}
#section07 .sec7_cont .left .smallProfile {
  margin: 20px auto 0;
  width: 158px;
  height: 44px;
  background: rgba(54, 54, 54, 0.25);
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 15px;
}
#section07 .sec7_cont .left .smallProfile .img {
  /* width: 36px; */
  width: 2vw;
  margin-right: 17px;
}
#section07 .sec7_cont .left .smallProfile p {
  /* font-size: 21px; */
  font-size: 1.2vw;
  /* font-weight: bold; */
  color: #fff;
  letter-spacing: -0.63px;
}

#section07 .sec7_cont .right {
  /* width: 48.4%; */
  width: 70%;
  /* width: 930px; */
}
#section07 .sec7_cont .chatCont {
  width: 90%;
  /* margin-bottom: 18px; */
}
#section07 .sec7_cont .chatCont.chatSend {
  margin-left: 10%;
}
#section07 .sec7_cont .chatReceive .chatProfile {
  display: flex;
  justify-content: flex-end;
  margin-right: 27px;
  position: relative;
  z-index: 1;
}
#section07 .sec7_cont .chatSend .chatProfile {
  display: flex;
  justify-content: flex-start;
  margin-left: 27px;
  position: relative;
  z-index: 1;
}
#section07 .sec7_cont .chatReceive .info {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  margin-right: 17px;
}
#section07 .sec7_cont .chatSend .info {
  display: flex;
  justify-content: flex-start;
  padding-top: 12px;
  margin-left: 17px;
}
#section07 .sec7_cont .chatCont .chatProfile .name {
  /* font-size: 20px; */
  font-size: 1.2vw;
  letter-spacing: -0.6px;
  color: #fff;
}
#section07 .sec7_cont .chatCont .name {
  margin-right: 5px;
}
#section07 .sec7_cont .chatCont .chatProfile .time {
  /* font-size: 19px; */
  font-size: 1.1vw;
  letter-spacing: -0.57px;
  color: #818181;
}
#section07 .sec7_cont .chatCont .chatProfile .img {
  /* width: 78px; */
  width: 4.5vw;
}

#section07 .sec7_cont .chatSub {
  width: 100%;
  /* background: #363636; */
  background: rgba(54, 54, 54, 0.25);
  border-radius: 15px;
  padding: 16px 30px;
  transform: translateY(-27px);
}
#section07 .sec7_cont .chatSub .chatImg {
  margin: 13px 0 0;
  /* width: 502px; */
  width: 60%;
}
#section07 .sec7_cont .chatSub p {
  margin: 20px 0;
}
#section07 .sec7_cont .chatSub p span {
  /* font-size: 21px; */
  font-size: 1.2vw;
  letter-spacing: -0.63px;
  color: #fff;
  display: block;
  font-weight: 400;
  line-height: 1.62;

  white-space: pre-line;
}

#section07 .sec7_cont .chat02 .chatSub {
  background: #2f2f2f;
  box-shadow: 6px 8px 15px 0 rgba(0, 0, 0, 0.51);
}

#section08 {
  margin-top: 180px;
  padding-bottom: 148px;
}
#section08 .sec_inner {
  position: relative;
  overflow: hidden;
}
#section08 .svgWrap {
  /* width: 755px; */
  width: 37vw;
  float: right;
  position: relative;
}
#section08 .svgWrap .areaNames {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}
#section08 .svgWrap .areaNames p {
  font-size: 20px;
  letter-spacing: -0.63px;
  color: rgba(255, 255, 255, 0.72);
  position: absolute;
  text-transform: uppercase;
  font-weight: 400;
}
#section08 .svgWrap .areaNames p.ger {
  top: 8vw;
  left: 7vw;
}
#section08 .svgWrap .areaNames p.fra {
  top: 15.5vw;
  right: 4.5vw;
}
#section08 .svgWrap .areaNames p.kor {
  top: 23vw;
  right: 7vw;
}
#section08 .svgWrap .areaNames p.areas {
  top: 25vw;
  left: 0vw;
  text-align: right;
}
#section08 .svgWrap .areaNames p.areas2 {
  top: 29vw;
  left: 12vw;
}
#section08 #lineSvg {
  width: 100%;
}
#section08 svg path {
  stroke: #2e2f2f;
  display: block;
}
#section08 .sec8_textWrap {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
#section08 .sec8_textWrap .sec8_btn {
  margin-top: 81px;
  display: flex;
  line-height: 48.2px;
  height: 48.2px;
  text-align: center;
  cursor: pointer;
}
#section08 .sec8_textWrap .sec8_btn .text {
  font-size: 21px;
  color: #fff;
  width: 149.6px;
  height: 100%;

  background: rgba(54, 54, 54, 0.25);
  border-radius: 24.1px;
}
#section08 .sec8_textWrap .sec8_btn .arrow {
  width: 48.2px;
  height: 100%;
  background: #fff;
  border-radius: 50%;
}
#section08 .sec8_textWrap .sec8_btn .arrow img {
  width: 15px;
}
#section08 .sec8_textWrap .sec8_btn .img img {
  height: 100%;
}
#section08 .sec8_title {
  font-size: 111px;
  color: #fff;
  text-transform: uppercase;
  line-height: 0.96;
}
#section08 .path01 {
  stroke-dasharray: 2531;

  /* animation: lineAni01 5s ease; */
}
#section08 .path02 {
  stroke-dasharray: 1514;
  /* animation: lineAni02 5s ease; */
}
/* #section09 {
  margin-top: 13.5vw;
  height: 200vh;
  position: relative;
}
#section09 .sec9_inner {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
#section09 .sec9_bgWrap .sec9Bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
}
#section09 .sec9_bgWrap .sec9Bg.bg01 {
  background-image: url(./img/sec9_bg01.png);
}
#section09 .sec9_bgWrap .sec9Bg.bg02 {
  background-image: url(./img/sec9_bg02.png);
}
#section09 .sec9_bgWrap .sec9Img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
#section09 .sec9_bgWrap .imgWrap {
  opacity: 0.03;
}
#section09 .sec9_bgWrap .sec9Img.img01 {
  width: 24.01vw;
}
#section09 .sec9_bgWrap .sec9Img.img02 {
  width: 24.84vw;
}
#section09 .sec9_bgWrap .sec9Img.img03 {
  width: 24.01vw;
}
#section09 .sec9cont {
  position: absolute;
  top: 8vw;
  left: 50%;
  transform: translateX(-50%);
 
  width: 55%;
  z-index: 10;
}
#section09 .sec9cont h2 {
  font-size: 2.3vw;
  color: #fff;
  font-weight: bold;
  letter-spacing: -1.2px;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#section09 .sec9cont .cont_tWrap {
  position: absolute;
  top: 3vw;
  left: 0;
  width: 100%;
}
#section09 .sec9cont .mgbt {
  margin-bottom: 7px;
}
#section09 .sec9cont .detail {
  display: flex;
  margin-top: 1.75vw;
}
#section09 .sec9cont .percent {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  letter-spacing: -0.6px;
  font-style: italic;
  width: 13%;
}
#section09 .sec9cont .text {
  font-size: 19px;
  font-weight: 100;
  color: #727272;
  letter-spacing: -0.57px;
  line-height: 1.26;
  width: calc(100% - 13%);
  overflow: hidden;
}
#section09 .sec9cont p {
  position: relative;
}
#section09 .sec9cont p span {
  display: block;
  position: relative;
}

#section09 .sec9_bgWrap .sec9Bg {
  transition: opacity 3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
#section09 .sec9_bgWrap .sec9Img {
  transition: opacity 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.3s;
}

#section09.scroll01 .bg01 {
  opacity: 1;
}
#section09.scroll02 .bg02 {
  opacity: 1;
}
#section09.scroll03 .bg03 {
  opacity: 1;
}
#section09.scroll01 .sec9Img.img01 {
  opacity: 1;
}
#section09.scroll02 .sec9Img.img02 {
  opacity: 1;
}
#section09.scroll03 .sec9Img.img03 {
  opacity: 1;
}

#section09 .cont_tWrap .text span {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
}

#section09 .cont_tWrap.active .text span {
  opacity: 1;
  transform: translateX(0%);
}
#section09 .cont_tWrap.active .detail01 .text span {
  transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
}
#section09 .cont_tWrap.active .detail02 .text span {
  transition: opacity 0.7s ease-in-out 0.1s, transform 0.7s ease-in-out 0.1s;
}
#section09 .cont_tWrap.active .detail03 .text span {
  transition: opacity 0.7s ease-in-out 0.2s, transform 0.7s ease-in-out 0.2s;
}
#section09 .cont_tWrap.active .detail04 .text span {
  transition: opacity 0.7s ease-in-out 0.3s, transform 0.7s ease-in-out 0.3s;
}
#section09 .cont_tWrap.active .detail05 .text span {
  transition: opacity 0.7s ease-in-out 0.4s, transform 0.7s ease-in-out 0.4s;
}
#section09 .cont_tWrap.active .detail06 .text span {
  transition: opacity 0.7s ease-in-out 0.5s, transform 0.7s ease-in-out 0.5s;
}
#section09 .cont_tWrap.active .detail07 .text span {
  transition: opacity 0.7s ease-in-out 0.6s, transform 0.7s ease-in-out 0.6s;
}
#section09 .cont_tWrap.active .detail08 .text span {
  transition: opacity 0.7s ease-in-out 0.7s, transform 0.7s ease-in-out 0.7s;
}

#section09 .cont_tWrap .percent {
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
}
#section09 .cont_tWrap.active .percent {
  opacity: 1;
} */
#section09 {
  padding: 100px 0 350px;
  background: #191919;
  position: relative;
  overflow: hidden;
}
#section09 .missionTitle {
  text-align: center;
}
#section09 .missionTitle h2 {
  font-size: 40px;
  color: #fff;
  letter-spacing: -0.02em;
  font-weight: 900;
}
#section09 .missionTitle p {
  font-size: 19px;
  color: #727272;
  margin-top: 9px;
  font-weight: 100;
  letter-spacing: -0.05em;
}
#section09 .missionWrap {
  margin-top: 49px;
}
#section09 .missionCont {
  display: flex;
  justify-content: center;
  gap: 17px;
  margin-bottom: 20px;
}
#section09 .missionBox {
  padding: 12.5px 17px;
  background: #fff;
  border-radius: 0.7em;
  width: 290px;
}
#section09 .missionBox .num {
  font-size: 13px;
  color: rgba(68, 68, 68, 0.37);
  font-style: italic;
}
#section09 .missionBox .title {
  font-size: 18px;
  font-weight: 600;
  color: #444;
  margin: 1px 0 3px;
  text-transform: uppercase;
}
#section09 .missionBox .text {
  font-size: 13px;
  color: #444;
  font-weight: 300;
}
#section09 .missionBottom {
  position: absolute;
  bottom: 60px;
  left: 0;
}
#section09 .missionBottom p {
  font-size: 111px;
  font-weight: 900;
  color: transparent;
  background: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 1px #fff;
  text-transform: uppercase;
  white-space: nowrap;
  will-change: transform;
  letter-spacing: -5px;
  opacity: 0.25;
}
#section09 .missionBottom p.btText01 {
  animation: sec9Marquee 30s linear infinite;
}
#section09 .missionBottom p.btText02 {
  animation: sec9Marquee2 30s linear infinite;
  margin-top: -30px;
}

@keyframes sec9Marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
@keyframes sec9Marquee2 {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

#section10 {
  padding-top: 144px;
  padding-bottom: 137px;
  overflow: hidden;
}
#section10 .sec10_cont {
  display: flex;
}

#section10 .left {
  position: relative;
  width: 45%;
  margin-right: 5%;
  position: sticky;
  left: 0;
  bottom: 0;
}
#section10 .left h2 {
  /* font-size: 188px; */
  font-size: 10.8vw;
  color: #fff;
  letter-spacing: -5.64px;
  line-height: 0.97;
  font-weight: 900;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(180deg) translateX(11.8%);
}
#section10 .left h2 span {
  /* font-size: 143px; */
  font-size: 8.2vw;
  letter-spacing: -4.29px;
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}
#section10 .left .faqIcon {
  width: 68%;
  position: absolute;
  top: 10%;
  left: 60%;
  transform: translateX(-50%);
}
#section10 .right {
  width: 50%;
}
#section10 .right h2 {
  /* font-size: 179px; */
  font-size: 9.32vw;
  color: #fff;
  letter-spacing: -5.37px;
  line-height: 0.97;
  font-weight: 900;
}
#section10 .right h2 span {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}
#section10 .faqList {
  margin-top: 40px;
}

#section10 .faqCont h3 {
  /* font-size: 26px; */
  font-size: 1.51vw;
  color: #afafaf;
  letter-spacing: -0.78px;
  padding-bottom: 12px;
  border-bottom: 1px solid #707070;
  margin-bottom: 20px;
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
#section10 .faqCont p {
  /* font-size: 20px; */
  font-size: 1.15vw;
  color: #908f8f;
  letter-spacing: -0.6px;
  line-height: 1.4;
  font-weight: 100;
}
#section10 .faqImg {
  margin: 40px 0 5px;
}

#section10 .faqCont button {
  background: transparent;
  border: none;
  color: #707070;
}
#section10 .faqCont button svg {
  transition: transform 0.3s ease-in-out;
}
#section10 .faqCont.active button svg {
  transform: rotate(180deg);
}

#section10 .faqCont p {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}
#section10 .faqCont.active p {
  max-height: 300px;
}
#section10 .contact {
  margin-top: 71px;
  float: right;
  width: 50%;
}
#section10 .contact h4 {
  font-size: 44px;
  color: #fff;
  letter-spacing: -1.32px;
  margin-bottom: 34.5px;
}
#section10 .contact .btns {
  width: 75%;
  display: flex;
  gap: 10px 1.8%;
  flex-wrap: wrap;
}
#section10 .contact .btn {
  width: 49.1%;
  border: 1px solid #707070;
}
#section10 .contact .btn a {
  display: block;
  padding: 21px 25px;
  color: #fff;
  position: relative;
}
#section10 .contact .btn button {
  display: block;
  width: 100%;
  padding: 21px 25px;
  color: #fff;
  position: relative;
  background: transparent;
  text-align: left;
  border: none;
}

#section10 .contact .btn .arrow:after {
  content: "";
  display: inline-block;
  width: 9.1px;
  height: 9.1px;
  margin-left: 0.5rem;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 25px;
}

#section10 .contact .btn:hover {
  background: #fff;
  border: 1px solid #fff;
}
#section10 .contact .btn:hover a,
#section10 .contact .btn:hover button {
  color: #000;
}

#section10 .contact .btn:hover .arrow:after {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

#section03_m {
  display: none;
}

.dots_custom {
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
  padding: 0;
  width: 100%;
}
.dots_custom ul {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 1.63vw;
}

.dots_custom li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.dots_custom li button {
  border: none;
  background: rgba(255, 255, 255, 0.12);
  color: transparent;
  cursor: pointer;
  display: block;
  height: 1.63vw;
  width: 1.63vw;
  border-radius: 100%;
  padding: 0;
}

.dots_custom li.slick-active button {
  background-color: #fff;
}

/*스크롤애니메이션 scroll animation */
#section02 span.scrollSpan {
  transform: translateY(60px);
  opacity: 0;
  display: inline-block;
  transition: opacity 0.1s ease, transform 0.1s ease;
}
#section02 p.On span.scrollSpan {
  transform: translateY(0px);
  opacity: 1;
}

#section02 p.On span.scrollSpan.t1 {
  transition: opacity 0.7s ease-in-out 0.2s, transform 0.7s ease-in-out;
}

#section02 p.On span.scrollSpan.t2 {
  transition: opacity 0.7s ease-in-out 0.1s, transform 0.7s ease-in-out 0.1s;
}

#section02 p.On span.scrollSpan.t3 {
  transition: opacity 0.7s ease-in-out 0.2s, transform 0.7s ease-in-out 0.2s;
}

#section02 p.On span.scrollSpan.t4 {
  transition: opacity 0.7s ease-in-out 0.3s, transform 0.7s ease-in-out 0.3s;
}

#section04 h3,
#section04 p .scrollText {
  transform: translateY(60px);
  opacity: 0;
  position: relative;
  transition: opacity 0.1s ease, transform 0.1s ease;
}
#section04 p .scrollText {
  display: inline-block;
}
#section04.On h3,
#section04.On p .scrollText {
  transform: translateY(0px);
  opacity: 1;
  transition: opacity 0.7s ease-in-out 0.2s, transform 0.7s ease-in-out 0.2s;
}
#section04.On p .scrollText {
  transition: opacity 0.7s ease-in-out 0.6s, transform 0.7s ease-in-out 0.6s;
}
#section04 p::before {
  width: 0%;
  transition: width 0.1s ease;
}
#section04.On p::before {
  width: 100%;
  transition: width 0.7s ease-in-out 0.4s;
}
#section04 .img_wrap .imgBox img {
  position: relative;
  transform: translateY(100%);
  opacity: 0;
  transition: opacity 0.1s ease, transform 0.1s ease;
}
#section04.On .img_wrap .imgBox img {
  transform: translateY(0%);
  opacity: 1;
}
#section04.On .img_wrap .imgBox img.img03 {
  transition: opacity 1s ease-in-out 0.3s, transform 1s ease-in-out 0.3s;
}
#section04.On .img_wrap .imgBox img.img01 {
  transition: opacity 1s ease-in-out 0.4s, transform 1s ease-in-out 0.4s;
}
#section04.On .img_wrap .imgBox img.img02 {
  transition: opacity 1s ease-in-out 0.5s, transform 1s ease-in-out 0.5s;
}
#section04.On .img_wrap .imgBox img.img06 {
  transition: opacity 1s ease-in-out 0.6s, transform 1s ease-in-out 0.6s;
}
#section04.On .img_wrap .imgBox img.img04 {
  transition: opacity 1s ease-in-out 0.7s, transform 1s ease-in-out 0.7s;
}
#section04.On .img_wrap .imgBox img.img05 {
  transition: opacity 1s ease-in-out 0.8s, transform 1s ease-in-out 0.8s;
}

#section05 {
  position: relative;
}
#section05 .sec5_title h2 {
  letter-spacing: -0.5em;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  opacity: 0;
  transition: letter-spacing 0.1s ease, filter 0.1s ease, opacity 0.1s ease;
}
#section05 .sect_bgText {
  font-size: 160px;
  color: rgba(255, 255, 255, 0.03);
  font-weight: 900;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  text-transform: uppercase;
}
#section05 .sec5_title.On h2 {
  -webkit-filter: blur(0px);
  filter: blur(0px);
  opacity: 1;
  letter-spacing: normal;
  transition: letter-spacing 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s,
    filter 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s,
    opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;
}
#section05 .sec5_title p {
  transform: translateY(60px);
  opacity: 0;
  position: relative;
  transition: opacity 0.1s ease, transform 0.1s ease;
}
#section05 .sec5_title.On p {
  transform: translateY(0px);
  opacity: 1;
  transition: opacity 0.7s ease-in-out 0.4s, transform 0.7s ease-in-out 0.4s;
}

.around_title h2 {
  opacity: 0;
  transition: opacity 0.1s ease;
}

.around_title p {
  position: relative;
  transform: translateX(-60px);
  opacity: 0;
  transition: transform 0.1s ease, opacity 0.1s ease;
}
.around_title.On h2 {
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}

.around_title.On p {
  transform: translateX(0px);
  opacity: 1;
  transition: transform 0.7s ease-in-out 0.3s, opacity 0.7s ease-in-out 0.3s;
}

#section06 .bigTxt span {
  position: relative;
  transform: translateY(50%);
  opacity: 0;
  transition: transform 0.1s ease, opacity 0.1s ease;
}
#section06 .On span {
  transform: translateY(0%);
  opacity: 1;
}
#section06 .bigTxt.On span:nth-child(1) {
  transition: transform 0.5s ease-in-out 0.3s, opacity 0.5s ease-in-out 0.3s;
}
#section06 .bigTxt.On span:nth-child(2) {
  transition: transform 0.5s ease-in-out 0.4s, opacity 0.5s ease-in-out 0.4s;
}
#section06 .bigTxt.On span:nth-child(3) {
  transition: transform 0.5s ease-in-out 0.5s, opacity 0.5s ease-in-out 0.5s;
}
#section08 .sec8_title {
  -webkit-filter: blur(12px);
  filter: blur(12px);
  opacity: 0;
  transition: filter 0.1s ease, opacity 0.1s ease;
}
#section08.On .sec8_title {
  -webkit-filter: blur(0px);
  filter: blur(0px);
  opacity: 1;
  transition: filter 1s cubic-bezier(0.55, 0.085, 0.68, 0.53),
    opacity 1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

#section08 .path01 {
  stroke-dashoffset: 2531;
  transition: all 0.1s ease;
}
#section08 .path02 {
  stroke-dashoffset: -1514;
  transition: all 0.1s ease;
}
#section08.On .path01,
#section08.On .path02 {
  stroke-dashoffset: 0;
  transition: all 3s ease-in-out;
}
#section08 .svgWrap .areaNames p span {
  opacity: 0;
  transition: opacity 0.1s ease;
}
#section08.On .svgWrap .areaNames p span {
  opacity: 1;
}
#section08.On .svgWrap .areaNames p.ger span {
  transition: opacity 0.7s ease-in-out 0.5s;
}
#section08.On .svgWrap .areaNames p.fra span {
  transition: opacity 0.7s ease-in-out 0.8s;
}
#section08.On .svgWrap .areaNames p.kor span {
  transition: opacity 0.7s ease-in-out 1.1s;
}
#section08.On .svgWrap .areaNames p.areas .area01 {
  transition: opacity 0.7s ease-in-out 1.4s;
}

#section08.On .svgWrap .areaNames p.areas .area02 {
  transition: opacity 0.7s ease-in-out 1.5s;
}
#section08.On .svgWrap .areaNames p.areas .area03 {
  transition: opacity 0.7s ease-in-out 1.6s;
}
#section08.On .svgWrap .areaNames p .jpn {
  transition: opacity 0.7s ease-in-out 1.9s;
}
#section08.On .svgWrap .areaNames p .chn {
  transition: opacity 0.7s ease-in-out 1.9s;
}
#section08.On .svgWrap .areaNames p .twn {
  transition: opacity 0.7s ease-in-out 2s;
}

#section09 .missionTitle > * {
  opacity: 0;
  transform: translateY(60px);
  position: relative;
  transition: transform 0.1s ease, opacity 0.1s ease;
}

.On #section09 .missionTitle > * {
  opacity: 1;
  transform: translateY(0px);
}
.On #section09 .missionTitle h2 {
  transition: transform 0.7s ease-in-out 0.2s, opacity 0.7s ease-in-out 0.2s;
}
.On #section09 .missionTitle p {
  transition: transform 0.7s ease-in-out 0.3s, opacity 0.7s ease-in-out 0.3s;
}

#section09 .missionBox {
  opacity: 0;
  transition: opacity 0.1s ease;
}
.On #section09 .missionBox {
  opacity: 1;
}
.On #section09 .missionBox.box01 {
  transition: opacity 0.5s ease-in-out 0.4s;
}
.On #section09 .missionBox.box02 {
  transition: opacity 0.5s ease-in-out 0.5s;
}
.On #section09 .missionBox.box03 {
  transition: opacity 0.5s ease-in-out 0.6s;
}
.On #section09 .missionBox.box04 {
  transition: opacity 0.5s ease-in-out 0.7s;
}

.On #section09 .missionBox.box05 {
  transition: opacity 0.5s ease-in-out 0.8s;
}

.On #section09 .missionBox.box06 {
  transition: opacity 0.5s ease-in-out 0.9s;
}

.On #section09 .missionBox.box07 {
  transition: opacity 0.5s ease-in-out 1s;
}

.On #section09 .missionBox.box08 {
  transition: opacity 0.5s ease-in-out 1.1s;
}

.On #section09 .missionBox.box09 {
  transition: opacity 0.5s ease-in-out 1.2s;
}
